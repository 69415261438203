import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { Fade } from "react-awesome-reveal";
import Footer, { FooterPitch } from '../components/footer';
import TalkingPoint from '../components/talking-point';
import { WaveFooter, WaveHeader } from '../components/wave';
import aquinasLogo from '../public/AquinasLogo.svg';
import fastSearchImage from '../public/FastSearch.jpg';
import samplePhotoAlbumImage from '../public/SamplePhotoAlbum.jpg';
import texasFlagImage from '../public/TexasFlag.jpg';
import heroImage1 from '../public/hero-1.jpg';

const styles = {
    content: {
        padding: 64,
        marginLeft: 200,
    },
    infoTextContainer: {
        marginBottom: 32,
    },
}

interface AboutProps {
    verses: string[],
}
const About = (props: AboutProps): JSX.Element => {
    const [verse, setVerse] = useState("")

    useEffect(() => {
        setVerse(props.verses[Math.floor(Math.random() * props.verses.length)]);

    }, [props.verses])

    let landing = (<div className="leading-normal tracking-normal text-white gradient" style={{ fontFamily: "'Source Sans Pro', sans-serif" }}>
        <div className="pt-24">
            <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                <div className="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
                    <p className="uppercase tracking-loose w-full">Aquinas Photos</p>
                    <h1 className="my-4 text-5xl font-bold leading-tight">
                        Your Favorite Memories, With Artificial Intelligence
                    </h1>
                    <p className="leading-normal text-2xl mb-8">
                        Aquinas: the safest photo vault for your family.
                    </p>
                    <Link
                        href="/auth"
                        className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                        
                            Get Started
                        
                    </Link>
                </div>
                <div className="w-full md:w-3/5 py-6 text-center">
                    <Image className="w-full md:w-4/5 z-50" src={aquinasLogo} width="480" height="480" alt="Logo" priority={true}/>
                </div>
            </div>
        </div>
        <WaveHeader />
        <section className="bg-white border-b py-8">
            <div className="container max-w-5xl mx-auto m-8">
                <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                    Aquinas Photos
                </h1>
                <div className="w-full mb-4">
                    <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                </div>
                <TalkingPoint photo={samplePhotoAlbumImage} heading="Moments that take your breath away" notes="Aquinas assembles beautiful photo albums and montages around any topic or people you choose using cutting-edge artificial intelligence and computer vision." rightSide={true}></TalkingPoint>
                <TalkingPoint photo={fastSearchImage} heading="Find And Organize Photos on your terms" notes="Aquinas keeps data about your photos on your devices, allowing you to find and collect photos with lightning speed." rightSide={false}></TalkingPoint>

                <TalkingPoint photo={heroImage1} heading="Keep your images safe" notes={(
                    <div>
                        One in twenty homes are burglarized.  Aquinas has 99.999999999% (ELEVEN 9&apos;s!) of durability, keeping your photos safe for generations.
                        <br />
                        <br />
                        Read about <Link href="/privacy" className="text-indigo-600 underline">how we protect your data and privacy</Link>
                    </div>
                )} rightSide={true}></TalkingPoint>
                <TalkingPoint photo={heroImage1} heading="Bring Your Own Hosting" notes={(
                    <div>
                        Aquinas can store and retrieve photos from your personal Amazon storage bucket.
                    </div>
                )} rightSide={false}></TalkingPoint>

                <TalkingPoint photo={texasFlagImage} heading="Made in Texas" notes={(
                    <div>
                        We will <b>never</b> sell your information to advertizers.  You are the <b>customer, not the product</b>.
                        <br />
                        <br />
                        Learn more about <Link href="/what-we-believe" className="text-indigo-600 underline">what we believe</Link>.
                    </div>
                )} rightSide={true}></TalkingPoint>

            </div>
        </section>
        <section className="bg-white border-b py-8">
            <div className="container mx-auto flex flex-wrap pt-4 pb-12">
                <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                    Uploading your photos
                </h1>
                <div className="w-full mb-4">
                    <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                </div>
                <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                        <Link
                            href="/android"
                            className="flex flex-wrap no-underline hover:no-underline">
                            <p className="w-full text-gray-600 text-xs md:text-sm px-6">
                                &nbsp;
                            </p>
                            <div className="w-full font-bold text-xl text-gray-800 px-6">
                                Android
                            </div>
                            <p className="text-gray-800 text-base px-6 mb-5">
                                Automatically sync photos from any android device.  Get the Aquinas app on your phone.
                            </p>
                        </Link>
                    </div>
                    <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                        <div className="flex items-center justify-start">
                            <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                Get it from Google Play
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                        <Link
                            href="/migrate"
                            className="flex flex-wrap no-underline hover:no-underline">
                            <p className="w-full text-gray-600 text-xs md:text-sm px-6">
                                &nbsp;
                            </p>
                            <div className="w-full font-bold text-xl text-gray-800 px-6">
                                Bringing photos from another site
                            </div>
                            <p className="text-gray-800 text-base px-6 mb-5">
                                Get your photos from Google, Apple, Amazon.  Migrate your photo albums to Aquinas in a few easy steps.
                            </p>
                        </Link>
                    </div>
                    <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                        <div className="flex items-center justify-center">
                            <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                Learn more
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
                    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                        <Link
                            href="/desktop"
                            className="flex flex-wrap no-underline hover:no-underline">
                            <p className="w-full text-gray-600 text-xs md:text-sm px-6">
                                &nbsp;
                            </p>
                            <div className="w-full font-bold text-xl text-gray-800 px-6">
                                Keep photos on your desktop/laptop safe.
                            </div>
                            <p className="text-gray-800 text-base px-6 mb-5">
                                Download Aquinas Desktop for all major operating systems.
                            </p>
                        </Link>
                    </div>
                    <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                        <div className="flex items-center justify-end">
                            <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="bg-gray-100 py-8">
            <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
                <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                    Pricing
                </h1>
                <div className="w-full mb-4">
                    <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                </div>
                <div className="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
                    <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                        <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                            <div className="p-8 text-3xl font-bold text-center border-b-4">
                                Free
                            </div>
                            <ul className="w-full text-center text-sm">
                                <li className="border-b py-4">Free for early adopters</li>
                                <li className="border-b py-4">Tell us what works and what doesn&apos;t</li>
                                <li className="border-b py-4">Share Aquinas with your friends</li>
                            </ul>
                        </div>
                        <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                            <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                                &nbsp;
                            </div>
                            <div className="flex items-center justify-center">
                                <Link
                                    href="/auth"
                                    className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                    
                                        Get started
                                    
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/*
                    <div className="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10">
                        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                            <div className="w-full p-8 text-3xl font-bold text-center">Basic</div>
                            <div className="h-1 w-full gradient my-0 py-0 rounded-t"></div>
                            <ul className="w-full text-center text-base font-bold">
                                <li className="border-b py-4">TBD</li>
                            </ul>
                        </div>
                        <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                            <div className="w-full pt-6 text-4xl font-bold text-center">
                                $9.99&nbsp;
                                <span className="text-base">/ per month</span>
                            </div>
                            <div className="flex items-center justify-center">
                                <Link href="/auth">
                                    <a className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                        Get started
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
                        <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
                            <div className="p-8 text-3xl font-bold text-center border-b-4">
                                Pro
                            </div>
                            <ul className="w-full text-center text-sm">
                                <li className="border-b py-4">TBD</li>
                            </ul>
                        </div>
                        <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
                            <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
                                $99.99&nbsp;
                                <span className="text-base">/ per month</span>
                            </div>
                            <div className="flex items-center justify-center">
                                <Link href="/auth">
                                    <a className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                                        Get started
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                    */}
                </div>
            </div>
        </section>
        <WaveFooter />
        <FooterPitch />
        <Footer />
    </div>
    );

    return landing;
    return (
        <div>
            <Fade direction="left">
                <Image src={heroImage1} alt="Hero"></Image>
                <h2>Keep your images safe</h2>
                <div>Aquinas has 99.999999999% (11 9&apos;s) of durability</div>
            </Fade>
        </div>
    );
}

// This also gets called at build time
export async function getStaticProps({ }) {
    // Pass post data to the page via props
    let verses = ["Children’s children are a crown to the aged, and parents are the pride of their children. -Proverbs 17:6", "But Jesus called them to him, saying, \"Let the children come to me, and do not hinder them, for the kingdom of God belongs to such as these.\" -Luke 18:16"]
    return { props: { verses } }
}

export default About
