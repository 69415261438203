import Image from 'next/image';
import { Fade } from "react-awesome-reveal";

interface TalkingPointProps {
    heading: string,
    notes: any,
    rightSide: boolean,
    photo: any,
}
export default function TalkingPoint(props: TalkingPointProps): JSX.Element {
    if (props.rightSide) {
        return (
            <div className="flex flex-wrap mb-96">
                <div className="w-5/6 sm:w-1/2 p-6">
                    <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                        {props.heading}
                    </h3>
                    <div className="text-gray-600 mb-8">
                        {props.notes}
                    </div>
                </div>
                <div className="w-full sm:w-1/2 p-6">
                    <Fade direction="right">
                        <Image src={props.photo} priority alt="Talking Point Right"></Image>
                        <h2></h2>
                        <div></div>
                    </Fade>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-wrap mb-96">
            <div className="w-full sm:w-1/2 p-6">
                <Fade direction="left">
                    <Image src={props.photo} priority alt="Talking Point Left"></Image>
                    <h2></h2>
                    <div></div>
                </Fade>
            </div>
            <div className="w-5/6 sm:w-1/2 p-6">
                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                    {props.heading}
                </h3>
                <div className="text-gray-600 mb-8">
                    {props.notes}
                </div>
            </div>
        </div>
    );
}
