import Image from 'next/image';
import Link from 'next/link';
import aquinasLogo from '../public/AquinasLogo.svg';

export default function Footer() {
    return (
        <footer>
            <div className="container mx-auto px-8">
                <div className="w-full flex flex-col md:flex-row py-6">
                    <div className="flex-1 mb-6 text-black">
                        <Link
                            href="/auth"
                            className="text-gray-400 hover:text-indigo-600 text-opacity-50 no-underline hover:no-underline font-bold text-2xl lg:text-4xl">
                            <Image className="w-full md:w-4/5 z-50" src={aquinasLogo} width="48" height="48" alt="Aquinas Logo" />AQUINAS</Link>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-200 md:mb-6">Links</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    href="/faq"
                                    className="no-underline hover:underline text-gray-400 hover:text-indigo-600">FAQ</Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    href="/help"
                                    className="no-underline hover:underline text-gray-400 hover:text-indigo-600">Help</Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    href="https://form.jotform.com/212434818241148"
                                    className="no-underline hover:underline text-gray-400 hover:text-indigo-600">Give Feedback</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-200 md:mb-6">Legal</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    href="/terms"
                                    className="no-underline hover:underline text-gray-400 hover:text-indigo-600">Terms</Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    href="/privacy"
                                    className="no-underline hover:underline text-gray-400 hover:text-indigo-600">Privacy</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1 hidden">
                        <p className="uppercase text-gray-200 md:mb-6">Social</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    href=""
                                    className="no-underline hover:underline text-gray-400 hover:text-indigo-600">Facebook</Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    href=""
                                    className="no-underline hover:underline text-gray-400 hover:text-indigo-600">Linkedin</Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    href=""
                                    className="no-underline hover:underline text-gray-400 hover:text-indigo-600">Twitter</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-200 md:mb-6">Company</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    href=""
                                    className="no-underline hover:underline text-gray-400 hover:text-indigo-600">Official Blog</Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    href="/about-us"
                                    className="no-underline hover:underline text-gray-400 hover:text-indigo-600">About Us</Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link
                                    href="mailto:info@aquinas.photos"
                                    className="no-underline hover:underline text-gray-400 hover:text-indigo-600">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Link href="" className="text-gray-200">Background vector created by freepik - www.freepik.com</Link>
        </footer>
    );
}

export function FooterPitch() {
    return (
        <section className="container mx-auto text-center py-6 mb-12">
            <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
                Store your moments with Aquinas
            </h1>
            <div className="w-full mb-4">
                <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
            </div>
            <h3 className="my-4 text-3xl leading-tight">
                Your Favorite Memories, With Artificial Intelligence
            </h3>
            <Link
                href="/auth"
                className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                
                    Get Started
                
            </Link>
        </section>
    );
}
